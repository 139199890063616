<template>
  <div class="collect">
    <title-top>我的收藏</title-top>
    
    <div class="h20"></div>
    <div class="row h210 bg-white mb20" v-for="i in 10" :key="i">
        <img class="br4 imgsize176 ml25" src="~images/goods1.png" alt="" style="align-self: center;">
        <div class="flex1 column font24 mt25 f-1A1A1A ellipsis ml25 mr25">
            <p>AK男装2019新款轻复古印花oversized圆领套头卫衣百搭休闲1905201...</p>
            <div class="row between align-center f-F62341 mt40">
                <div class="row baseline">
                    <span>￥</span>
                    <span class="font32 fontwb space">172.98</span>
                    <span class="f-999999 line-through">￥187.98</span>
                </div>
                <span class="f-FFFFFF row align-center fanli h25 bg-9240F7 br5">预估返6.46</span>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from '@/components/common/title/TitleTop'

export default {
  name: 'MyCollect',
  data(){
    return {
      
    }
  },
  components: {
    TitleTop
  }
}
</script>
<style  scoped>
 .space{
    width: 2rem;
}
.fanli{
    padding: .106667rem .133333rem;
}

</style>
